<template>
  <b-sidebar
    id="add-gallery-image-sidebar"
    :visible="isAddGalleryImageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-gallery-image-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add Gallery Images</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <b-form-group label="Select Tag" label-for="Select Tag">
            <v-select
              multiple
              :close-on-select="true"
              v-model="selectedtag"
              :options="tag"
              label="name"
              input-id="add-text"
            >
            </v-select>
          </b-form-group>

          <br />
          <div style="display: flex; flex-direction: row; overflow: auto">
            <span
              style="
                border: 1px solid black;
                width: 70px;
                height: auto;
                position: relative;
              "
              v-for="(images, index) in multi_img"
              :key="index"
            >
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                style="
                  background: red;
                  width: 17px;
                  height: 17px;
                  position: absolute;
                  right: 0;
                "
                @click="deleteimage(index)"
              />
              <img style="width: 70px; height: 70px" :src="images.url" />
            </span>
          </div>
          <b-button variant="primary" @click="$refs.refInputEl.click()">
            <input
              ref="refInputEl"
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              class="d-none"
              multiple
              @input="inputImageRenderer"
            />
            <span class="d-none d-sm-inline">Upload Image</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>

          <br />
          <div
            class="d-flex mt-2"
            style="position: absolute; bottom: 3%; left: 3%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="hide"
              :disabled="submitbutton"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import JsonWebTokenError from "jsonwebtoken/lib/JsonWebTokenError";
import axios from "axios";
import S3 from "aws-s3";
export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddGalleryImageSidebarActive",
    event: "update:is-add-gallery-image-sidebar-active",
  },
  props: {
    isAddGalleryImageSidebarActive: {
      type: Boolean,
      required: true,
    },
    datatag: {
      type: Array,
      required: true,
    },
  },
  watch: {
    datatag() {
      this.tag = this.datatag;
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      gallerydata: {
        mutli_image_name: [],
        gallery_img: "",
      },
      post_gallery_data: "",
      multi_img: [],
      img_name: [],
      tag: [],
      selectedtag: [],
    };
  },
  computed: {
    submitbutton() {
      return !(this.selectedtag.length > 0 && this.multi_img.length > 0);
    },
  },
  created() {
  },
  methods: {
    selectFunction() {
    },
    getAllTag() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.tag = res.data;
        });
    },
    deleteimage(index) {
      this.multi_img.splice(index, 1);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.gallerydata = {
        gallery_img: "",
      };
      this.img_name = [];
      this.multi_img = [];
      this.gallerydata.mutli_image_name = [];
      this.selectedtag = [];
    },
    onSubmit() {
      this.gallerydata.mutli_image_name = this.img_name;
      let tagArr = [];
      for (let i = 0; i < this.selectedtag.length; i++) {
        tagArr.push(this.selectedtag[i]._id);
      }
      this.gallerydata.TagId = tagArr;
      this.gallerydata.multi_img = this.multi_img;
      this.post_gallery_data = this.gallerydata;
      this.$emit("clickedAddGalleryData", this.post_gallery_data);
      this.gallerydata.title = null;
      this.img_name = [];
      this.multi_img = [];
      this.gallerydata.mutli_image_name = [];
    },
    getFileExtension(mimeType) {
      const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
            };
            return mimeToExtension[mimeType] || 'png';
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                var urlimageObject = {};
                urlimageObject["url"] = s3Url;
                urlimageObject["name"] = file["name"];

                this.multi_img.push(urlimageObject);

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
   async inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        input.target.files.forEach(async file => {
            if (file) {
              try {
                  const fileType = file.type;
                  const fileExtension = this.getFileExtension(fileType);
                  const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                      type: file.type,
                      ext: fileExtension
                  }, {
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("_t"),
                      },
                  });
                  const { url, key } = response.data;
                  const extention = fileExtension == 'json' ? 'lottie' : fileExtension
                  await this.uploadFile(file, url, key, extention);
              } catch (error) {
                  console.error('Error getting pre-signed URL:', error);
              }
          } 
          });
        
  

        }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
